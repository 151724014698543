import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { map } from 'lodash';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  LIMIT,
  MODE_OF_CONDUCT,
  ORDER,
  QUESTION_ID,
  QUIZ_LIST_MODULE,
  ROUTES,
  SORT_ON,
  STATUS_LIST,
} from '../../common/constants';
import TableComponent from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import useRouter from '../../hooks/useRouter';
import QuizQuestionForm from './components/QuizQuestionForm';
import {
  GET_PARTICIPANTS,
  GET_SESSIONS,
  GET_SINGLE_QUIZ_BY_ID,
} from './graphql/Queries';

function Details() {
  const { params, navigate } = useRouter();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 0,
    showSizeChanger: false,
  });

  const [
    getParticipants,
    { data: participantsData, loading: participantsLoading },
  ] = useLazyQuery(GET_PARTICIPANTS, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        sessionId: params?.id,
      },
    },
    onError() {},
  });

  const sessionColumns = [
    {
      title: 'Date & Time',
      dataIndex: 'sessionDate',
      key: 'sessionDate',
      render: (_, value) =>
        dayjs(value?.sessionDate)?.format('DD-MM-YYYY h:mm:ss A'),
    },
    {
      title: 'Highest Score',
      dataIndex: 'highestScore',
      key: 'highestScore',
      render: (_, value) =>
        Number(value?.highestScore) > 0
          ? Math?.round(Number(value?.highestScore) * 100) / 100
          : 0,
    },
    {
      title: 'Participants',
      dataIndex: 'totalUsers',
      key: 'totalUsers',
      render: (_, value) => value?.totalUsers || 0,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, { id = '', totalUsers }) =>
        totalUsers !== '0' ? (
          <Tooltip title="View user list">
            <span className="pointer">
              <EyeOutlined
                onClick={async () => {
                  const { data } = await getParticipants({
                    variables: {
                      data: {
                        sessionId: id,
                      },
                    },
                  });
                  if (data) {
                    setIsModalOpen(true);
                  }
                }}
              />
            </span>
          </Tooltip>
        ) : (
          <EyeInvisibleOutlined />
        ),
    },
  ];

  const ParticipantsColums = [
    {
      title: 'Name',
      dataIndex: 'userName',
      key: ' userName',
      render: (_, value) => value?.userName || '-',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      render: (_, value) =>
        Number(value.score) > 0
          ? Math?.round(Number(value?.score) * 100) / 100
          : 0,
    },
    {
      title: 'Total Correct Answers',
      dataIndex: 'totalCorrectAnswers',
      key: 'totalCorrectAnswers',
      render: (_, value) => value?.totalCorrectAnswers,
    },
  ];
  const [form] = Form?.useForm();
  const watchForm = Form.useWatch(['questionData'], form);

  const { data: sessionList, loading: getSessionLoading } = useQuery(
    GET_SESSIONS,
    {
      variables: {
        data: {
          quizId: params?.id,
        },
        filter: {
          sortBy: ORDER[0].value,
          sortOn: SORT_ON[0].value,
          skip: paginationConfig?.skip,
          limit: LIMIT,
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const { loading, data } = useQuery(GET_SINGLE_QUIZ_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      data: {
        quizId: params?.id,
      },
    },
    onCompleted: (res) => {
      form.setFieldsValue({
        ...res?.getQuizById?.quiz,
        questionInterval: parseFloat(res?.getQuizById?.quiz?.intervalTime),
        questionData: res?.getQuizById?.quiz?.questions?.map((items) => ({
          ...items,
          optionsValues: items?.optionsValues?.map((item, idx) => ({
            title: item?.title,
            correctAnswer: items?.correctAnswer === QUESTION_ID[idx]?.value,
          })),
        })),
      });
    },
    onError() {},
  });
  return (
    <>
      <Card
        className="ant-body-scroll"
        title={
          <>
            <div className="d-flex justify-between">
              <div />
              <div>
                <Space size="small" wrap>
                  {searchParams?.get('tab') !== QUIZ_LIST_MODULE.SESSIONS ? (
                    <>
                      <Button
                        type="primary"
                        onClick={() => {
                          navigate(
                            `/edit/${params?.id}${
                              searchParams?.get('tab') === QUIZ_LIST_MODULE.QUIZ
                                ? '#quiz-questions-section'
                                : ''
                            }`,
                          );
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => navigate(ROUTES.MAIN)}
                      >
                        Back
                      </Button>
                    </>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => navigate(ROUTES.MAIN)}
                    >
                      Back
                    </Button>
                  )}
                </Space>
              </div>
            </div>
          </>
        }
      >
        <Form
          form={form}
          disabled
          layout="vertical"
          className="details-form card-body-wrapper"
        >
          <Tabs
            activeKey={searchParams.get('tab') || QUIZ_LIST_MODULE.DETAILS}
            onChange={(key) => setSearchParams({ tab: key })}
            defaultActiveKey={QUIZ_LIST_MODULE.DETAILS}
            className="tabs"
            items={[
              {
                key: QUIZ_LIST_MODULE.DETAILS,
                label: QUIZ_LIST_MODULE.DETAILS,
                children: loading ? (
                  <LoaderComponent size="small" />
                ) : (
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                          {
                            required: true,
                            message: 'Title required!',
                          },
                        ]}
                      >
                        <Input placeholder="Enter Title" maxLength={100} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: 'Description required!',
                          },
                        ]}
                      >
                        <Input placeholder="Enter Title" maxLength={250} />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Quiz Start Time (Seconds)"
                        name="quizInitialDelay"
                        rules={[
                          {
                            required: true,
                            message: 'Quiz Start Time required!',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Enter Time (Seconds)"
                          min={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Question Attempt Time (Seconds)"
                        name="questionInterval"
                        rules={[
                          {
                            required: true,
                            message: 'Interval Time required!',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Enter Time (Seconds)"
                          min={5}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Marks Per Question"
                        name="marksPerQuestion"
                        rules={[
                          {
                            required: true,
                            message: 'marksPerQuestion required!',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Enter MarksPerQuestion"
                          min={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="publishStatus"
                        label="Publish Status"
                        rules={[
                          { required: true, message: 'Status required!' },
                        ]}
                      >
                        <Select placeholder="Select Status">
                          {map(STATUS_LIST, (list) => (
                            <Select.Option
                              key={list?.value}
                              value={list?.value}
                            >
                              {list?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="modeOfConduct" label="Mode">
                        <Select
                          placeholder="Select mode"
                          defaultValue={MODE_OF_CONDUCT.QUIZ.key}
                        >
                          {map(MODE_OF_CONDUCT, (mode) => (
                            <Select.Option key={mode?.key} value={mode?.key}>
                              {mode?.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                ),
              },
              {
                key: QUIZ_LIST_MODULE.QUIZ,
                label: QUIZ_LIST_MODULE.QUIZ,
                children:
                  data?.getQuizById?.quiz?.questions?.length > 0 ? (
                    <QuizQuestionForm
                      addNewQuiz={false}
                      isFormDisabled
                      watchForm={watchForm}
                    />
                  ) : (
                    <Empty description="No quiz" />
                  ),
              },
              {
                key: QUIZ_LIST_MODULE.SESSIONS,
                label: QUIZ_LIST_MODULE.SESSIONS,
                children: (
                  <TableComponent
                    columns={sessionColumns}
                    data={sessionList?.getSessions?.sessions}
                    loadingData={getSessionLoading}
                    paginationConfig={{
                      ...paginationConfig,
                      total: sessionList?.getSessions?.count,
                    }}
                    onChange={(pagination) => {
                      setPaginationConfig((prev) => ({
                        ...prev,
                        skip: (pagination?.current - 1) * LIMIT,
                      }));
                    }}
                  />
                ),
              },
            ]}
          />
        </Form>
        <Modal
          title="User List"
          open={isModalOpen}
          okButtonProps={{
            style: {
              display: 'none',
            },
          }}
          cancelButtonProps={{
            style: {
              display: 'none',
            },
          }}
          onCancel={() => setIsModalOpen(false)}
        >
          <TableComponent
            columns={ParticipantsColums}
            data={participantsData?.getParticipants?.participants}
            rowClassName="pointer"
            loadingData={participantsLoading}
          />
        </Modal>
      </Card>
    </>
  );
}

export default Details;
